.file-input-container {
    background-color: aliceblue;
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem 0rem;
}

.file-input-container label {}

.file-input-container label span {}

.file-input .heading {
    font-size: 1.5rem;
    font-weight: 900;
    color: #000;
    margin-top: 20px;
    margin-bottom: 10px;
}

.file-input .content {
    font-size: 0.9rem;
    font-weight: 500;
    color: #585858;
}

.drag-box {}

.drag-box .drag-container {
    height: 200px;
    width: 100%;
    border: 2px dashed rgb(112, 136, 216);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
}

#drag-file-element {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffff;
    font-size: 18px;
}

.drag-box .drag-container .drag-icon {
    text-align: center;

}

.drag-box .drag-container .drag-icon .icon {
    text-align: center;
    font-size: 60px;
    color: rgb(112, 136, 216);

}

.drag-box .drag-container .drag-icon p {
    font-size: 17px;
    color: #000;
    font-weight: 700;
}

.drag-box .drag-container .drag-icon span {
    color: rgb(112, 136, 216);
    font-size: 15px;
    font-weight: 500;
}

.preview-image {
    height: 500px;
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
}